import React from 'react'
import './style.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <a
              style={{
                all: 'unset',
                display: 'flex',
                alignItems: 'center',
                gap: 15,
                fontSize: 25,
                cursor: 'pointer',
              }}
              href="/"
            >
              <ion-icon name="bag"></ion-icon>
              <h1>OZKURT SHOP</h1>
            </a>
            <p>
              Explore a wide range of electronics and furniture at our
              e-commerce site, your one-stop shop for quality products. Discover
              the perfect blend of functionality and style for your home and
              gadgets.
            </p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2 style={{ fontWeight: 'bold' }}>ABOUT US</h2>
            <ul>
              <li>
                {' '}
                <Link to="/login" className="mail">
                  - Careers
                </Link>{' '}
              </li>
              <li>
                <Link to="/login" className="mail">
                  - Our Stores
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to="/login" className="mail">
                  - Our Cares
                </Link>{' '}
              </li>
              <li>
                <Link to="/login" className="mail">
                  - Terms & Conditions{' '}
                </Link>
              </li>
              <li>
                <Link to="/login" className="mail">
                  - Privacy Policy
                </Link>{' '}
              </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2 style={{ fontWeight: 'bold' }}>CUSTOMER CARE</h2>
            <ul>
              <li>
                {' '}
                <Link to="/login" className="mail">
                  - Help Center
                </Link>{' '}
              </li>
              <li>
                <Link to="/login" className="mail">
                  - How to Buy
                </Link>{' '}
              </li>
              <li>
                {' '}
                <Link to="/login" className="mail">
                  - Track Your Order
                </Link>{' '}
              </li>
              <li>
                <Link to="/login" className="mail">
                  - Corporate & Bulk Purchasing{' '}
                </Link>
              </li>
              <li>
                <Link to="/login" className="mail">
                  - Returns & Refunds
                </Link>{' '}
              </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2 style={{ fontWeight: 'bold' }}>CONTACT US</h2>
            <ul>
              <li>
                <address>2501 Chatham Rd Suite N Springfield, IL 62704</address>
              </li>
              <li>
                Email:&nbsp;
                <a className="mail" href="mailto:support@algullshop.com">
                  support@ozkurtshop.com
                </a>
              </li>
              <li>
                Phone:&nbsp;
                <a className="mail" href="tel:309-305-1508">
                  +90 539 220 4927
                </a>
              </li>
            </ul>
            <div style={{ fontSize: 35, display: 'flex', gap: 15 }}>
              <i className="fa-solid fa-credit-card"></i>
              <i className="fa-brands fa-cc-mastercard"></i>
              <i className="fa-brands fa-cc-visa"></i>
              <i className="fa-brands fa-cc-paypal"></i>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
